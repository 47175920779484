import React, {useEffect, useRef, useState} from 'react';
import {
    Layout,
    ErrorComponent,
    ReadyPage,
    LightTheme,
    CssBaseline,
    ThemeProvider,
    GlobalStyles,
    RefineSnackbarProvider,
    notificationProvider, Modal, Box, Typography, Dialog,
} from "@pankod/refine-mui";
import {AuthProvider, Refine, useModal} from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router-v6";
import dataProvider from "@pankod/refine-simple-rest";
import axios, {AxiosRequestConfig, AxiosInstance, AxiosPromise, AxiosResponse, AxiosError} from "axios";
import './App.css';

import {ProjectList} from "./pages/projects/list";
import {ProjectShow} from "./pages/projects/show";
import {ProjectEdit} from "./pages/projects/edit";
import {ProjectCreate} from "./pages/projects/create";
import {createAuthProvider} from "./components/auth-provider";
import {clearLogin, LoginPage} from "./components/login";
import {CustomTitle} from "./components/CustomTitle";
import {EventList} from "./pages/events/list";

function App() {

    const authProvider = createAuthProvider();

    const axiosInstance = axios.create();
    axiosInstance.interceptors.request.use(
        (request: AxiosRequestConfig) => {
            const localToken = localStorage.getItem("token");
            console.debug(`got token for axios ${localToken?.substring(0, 10)} ... (length ${localToken?.length})`);
            if (!localToken || localToken.length < 1) {
                console.debug("redirecting to /login via axios intercept setup");
                window.location.href = "/login";
            }
            if (request.headers) {
                request.headers["Authorization"] = `Bearer ${localToken}`;
            } else {
                request.headers = {Authorization: `Bearer ${localToken}`};
            }
            return request;
        },
    );
    axiosInstance.interceptors.response.use(
        (response: AxiosResponse) => {
            console.debug("got response", response);
            return response;
        },
        (error: AxiosError) => {
            console.debug("got error", error, "code", error.response?.status);
            if (error.response?.status === 401) {
                clearLogin();
            }
            throw error;
        }
    )

    return (
        <ThemeProvider theme={LightTheme}>
            <CssBaseline/>
            <GlobalStyles styles={{html: {WebkitFontSmoothing: "auto"}}}/>
            <RefineSnackbarProvider>
                <Refine
                    authProvider={authProvider}
                    // LoginPage={AuthPage}
                    // LoginPage={LoginPage}
                    routerProvider={{
                        ...routerProvider,
                        // routes: [
                        //     {
                        //         path: "/login",
                        //         element: <AuthPage
                        //             forgotPasswordLink={false}
                        //             registerLink={false}
                        //             rememberMe={false} />,
                        //     },
                        // ],
                        routes: [
                            {
                                path: "/login",
                                element: <LoginPage />,
                            },
                        ],
                    }}
                    dataProvider={dataProvider(
                        "/api/admin", axiosInstance
                    )}
                    options={{
                        disableTelemetry: true
                    }}
                    Footer={() => (
                        <div
                            style={{
                                backgroundColor: "white",
                                height: "64px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            Usher Admin UI
                        </div>
                    )}
                    Title={CustomTitle}
                    notificationProvider={notificationProvider}
                    Layout={Layout}
                    ReadyPage={ReadyPage}
                    catchAll={<ErrorComponent/>}
                    resources={[
                        {
                        name: "projects",
                        list: ProjectList,
                        show: ProjectShow,
                        edit: ProjectEdit,
                        create: ProjectCreate
                    },
                        {
                            name: "events",
                            list: EventList
                        }
                    ]}
                />
            </RefineSnackbarProvider>
        </ThemeProvider>
    );
}

export default App;
