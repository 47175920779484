import {AuthProvider} from "@pankod/refine-core";
import {clearLogin} from "./login";

export const createAuthProvider = () => {
    return {
        login: () => {
            console.debug("redirecting to /login via authprovider");
            window.location.href = "/login";
            return Promise.resolve({});
        },
        register: () => Promise.reject(),
        updatePassword: () => Promise.reject(),
        forgotPassword: () => Promise.reject(),
        logout: () => {
            clearLogin();
            return Promise.resolve();
        },
        checkError: () => Promise.resolve(),
        checkAuth: () => Promise.resolve(),
        getPermissions: () => Promise.resolve(),
        getUserIdentity: () => {
            const email = localStorage.getItem("email");
            const id = localStorage.getItem("id");
            if (!email || !id) {
                return Promise.resolve(false);
            }
            return Promise.resolve({
                id: id,
                name: email
            })
        }
    } as AuthProvider
}
