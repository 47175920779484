import React from 'react';

import { useOne, useShow } from "@pankod/refine-core";
import {Show, Stack, Typography, TagField, EditButton} from "@pankod/refine-mui";
import {ProjectDTO} from "../../generated/UsherTypes";

export const ProjectShow: React.FC = () => {
    const { queryResult } = useShow<ProjectDTO>();

    const { data, isLoading } = queryResult;
    const record = data?.data;

    return (
        <Show isLoading={isLoading}>
            <Stack gap={1}>
                <Typography variant={"h6"}>Project Created</Typography>
                <Typography variant={"body1"}>
                    {(record?.created) && (
                        <>{record?.created}</>
                    )}
                </Typography>
                <Typography variant={"h6"}>Name</Typography>
                <Typography variant={"body1"}>{record?.name}</Typography>
                <Typography variant={"h6"}>Status</Typography>
                <Typography variant={"body1"}>
                    {Boolean(record?.active && record.active) && (
                        <>Project is currently active</>
                    )}
                    {Boolean(!record?.active && record?.active === false) && (
                        <>Project is not active (not collecting data)</>
                    )}
                </Typography>
                <Typography variant={"h6"}>Description</Typography>
                <Typography variant={"body1"}>{record?.description}</Typography>
                <Typography variant={"h6"}>Permissions</Typography>
                    {Boolean(record?.permissions && record.permissions.length > 0) && (
                        <ul>
                            {record?.permissions?.map((r, k) => (
                                <li key={k}>{r.name} <small>[{r.description}]</small></li>
                            ))}
                        </ul>
                    )}
                    {Boolean(!record?.permissions || record.permissions.length === 0) && (
                        <Typography variant={"body1"}>No additional device permissions requested</Typography>
                    )}
                <Typography variant={"h6"}>Enrolment PIN</Typography>
                <Typography variant={"body1"}>{record?.pin}</Typography>
                <EditButton recordItemId={record?.id} />
            </Stack>
        </Show>
    );
};
