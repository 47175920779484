import React, {useEffect, useRef} from 'react';
import Keycloak from 'keycloak-js';

export const clearLogin = () => {
    console.log("logging out (clearing login token)");
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    localStorage.removeItem("id");
}

export const LoginPage: React.FC = () => {

    // const kc = new Keycloak('/keycloak.json');
    // kc.init({}).then(() => {
    //     console.debug("checking whether we are authenticated");
    //     if (kc.authenticated) {
    //         console.debug("authenticated, now looking up profile")
    //         kc.loadUserProfile().then((profile) => {
    //             console.debug("setting email and token for user", profile.email);
    //             localStorage.setItem("id", profile.id || '');
    //             localStorage.setItem("email", profile.email || '');
    //             localStorage.setItem("token", kc.token || '');
    //         }).then(() => {
    //             console.debug("redirecting to /projects")
    //             window.location.href = "/projects";
    //             return;
    //         })
    //     }
    //     else {
    //         console.debug("requesting login()")
    //         kc.login();
    //         return;
    //     }
    // })

    const processLogin = () => {
        return kc.current.loadUserProfile().then((profile) => {
            console.debug("logged in with profile", profile);
            localStorage.setItem("token", kc.current.token || '');
            localStorage.setItem("email", profile.email || '');
            localStorage.setItem("id", profile.id || '');
        });
    }

    const kc = useRef(new Keycloak('/keycloak.json'));
    useEffect(() => {
        kc.current.init({
            // redirectUri: window.location.protocol + "//" + window.location.host + "/?logged-in"
            checkLoginIframe: true,
            // onLoad: "login-required"
        }).then(() => {
            console.debug("registering callbacks");
            kc.current.onAuthSuccess = () => {
                console.debug("successfully authenticated");
                processLogin();
            }
            kc.current.onAuthLogout = () => {
                console.debug("called logout");
                clearLogin();
            }
            kc.current.onReady = () => {
                console.debug(`keycloak ready, authenticated=${kc.current.authenticated}`);
                if (!kc.current.authenticated) {
                    clearLogin();
                    kc.current.login().then(() => {
                        console.debug("login done");
                    }).catch(() => {
                        console.debug("login failed")
                    });
                    return;
                }
                else {
                    processLogin().then(() => {
                        console.debug("redirecting to /projects via login.tsx");
                        window.location.href = "/projects";
                        return;
                    });
                }
            }
        }).catch(() => {
            console.debug("Error in init");
            clearLogin();
        })
    }, []);

    // useEffect(() => {
    //     if (!keycloak) {
    //         console.debug("Keycloak not initialised");
    //         return;
    //     }
    //
    //     const email = localStorage.getItem("email");
    //     const token = localStorage.getItem("token");
    //
    //     console.debug("keycloak status", keycloak);
    //
    //     if (keycloak && (!email || !token || email === '' || token === '')) {
    //         console.debug("No tokens found: redirecting");
    //         keycloak.login();
    //         return;
    //     }
    //
    //     if (keycloak && keycloak.authenticated === false) {
    //         console.debug("Not authenticated: redirecting")
    //         // window.location.href = keycloak.createLoginUrl();
    //         return;
    //     }
    //
    //     if (keycloak && keycloak.authenticated === true) {
    //         keycloak.loadUserProfile().then((profile) => {
    //             localStorage.setItem("email", profile.email || '');
    //             localStorage.setItem("token", keycloak.token || '');
    //         })
    //             .then(() => {
    //                 window.location.href = "/projects";
    //             })
    //     }
    // }, [keycloak]);

    return (
        <>
            <div>Please wait ...</div>
        </>
    )
}
