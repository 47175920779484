import React, {useEffect, useState} from 'react';

import {
    useDataGrid,
    DataGrid,
    GridColumns,
    TagField,
    DateField,
    List, Button, Typography, createStyles, Alert,
} from "@pankod/refine-mui";
import {
    BarController,
    BarElement,
    CategoryScale,
    Chart, ChartOptions,
    CoreChartOptions,
    Point,
    LinearScale, LineController,
    TimeScale,
    Tooltip, LineElement, PointElement, ChartData
} from "chart.js";
import {ReactChart} from "chartjs-react";
import {CalendarDTO} from "../../generated/UsherTypes";
import '../../Styles.css';
import axios, {AxiosError} from "axios";

export const EventList = () => {
    ReactChart.register(CategoryScale, LineController, BarController, LinearScale, LineElement, PointElement, BarElement, TimeScale, Tooltip);

    const chartOption: ChartOptions = {
        elements: {
            line: {
                tension: 0.4
            }
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                min: 0,
                title: {
                    display: true,
                    text: "Events"
                }
            },
            x: {
                title: {
                    display: true,
                    text: "Day of month"
                }
            }
        }
    };

    const [data, setData] = useState([0]);
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [currentMonth, setCurrentMonth] = useState(new Date().getUTCMonth() + 1);
    const [labels, setLabels] = useState([] as string[]);

    useEffect(() => {
        axios.get<CalendarDTO>("/api/admin/events/summary", {
            params: {
                year: currentYear,
                month: currentMonth
            },
            headers: {
                authorization: `Bearer ${localStorage.getItem("token")}`
            }
        })
            .then((res) => {
                if (res.status === 200) {
                    return res.data;
                }
                throw new Error("Unable to retrieve data");
            })
            .then((calendar) => {
                console.debug("got calendar", calendar);
                const dataByDay = calendar.days?.map(((value) => {return value.events})) as number[];
                setData(dataByDay || [0]);
                setLabels(Array(calendar.days?.length).fill(0).map(((value, index) => {return String(index + 1).padStart(2, '0')})));
                // setData();
            })
            .catch((reason: AxiosError) => {
                if (reason.response?.status === 401) {
                    console.debug("must reauthenticate, redirecting to /login")
                    return window.location.href = "/login";
                }
                else {
                    throw new Error("Unable to retrieve data");
                }
            })
    }, [currentMonth, currentYear]);

    const doPrevious = () => {
        if (currentMonth === 1) {
            setCurrentYear(currentYear - 1);
            setCurrentMonth(12);
        }
        else {
            setCurrentMonth(currentMonth - 1);
        }
    }

    const doNext = () => {
        if (currentMonth === 12) {
            setCurrentYear(currentYear + 1);
            setCurrentMonth(1);
        }
        else {
            setCurrentMonth(currentMonth + 1);
        }
    }

    const [chartData, setChartData] = useState({} as ChartData);
    useEffect(() => {
        setChartData({
            labels: labels,
            datasets: [
                {data}
            ]
        })
    }, [data, labels])

    return (

        <List>
            <Typography variant={"h6"}>
                Date: {currentYear}-{String(currentMonth).padStart(2, '0')}
            </Typography>
            <Typography variant={"body1"}>
                {data.reduce((accum, cv) => accum + cv)} events found
            </Typography>

            <div>
                <Button className={"hm-3 vm-3"} variant={"outlined"} onClick={doPrevious}>Previous month</Button>
                <Button className={"hm-3 vm-3"} variant={"outlined"} onClick={doNext}>Next month</Button>
            </div>

            <div style={{maxHeight: 300}}>
                <ReactChart
                    type="line"
                    data={chartData}
                    options={chartOption}
                    height={300}
                />
            </div>

            {(Math.max(...data) === 0) && (
                <Alert severity={"warning"}>
                    No data for {currentYear}-{String(currentMonth).padStart(2, '0')}
                </Alert>
            )}
        </List>

    );
};
