import React from 'react';

import {
    useDataGrid,
    DataGrid,
    GridColumns,
    TagField,
    DateField,
    List, ShowButton, EditButton, Show,
} from "@pankod/refine-mui";
import {ProjectDTO} from "../../generated/UsherTypes";


const columns: GridColumns<ProjectDTO> = [
    {
        field: "name",
        headerName: "Project name",
        flex: 1,
        minWidth: 100
    },
    {
        field: "description",
        headerName: "Description",
        minWidth: 300,
        flex: 1
    },
    {
        field: "created",
        headerName: "Created",
        minWidth: 220,
        renderCell: function render({row}) {
            return <DateField format="LLL" value={row.created} />;
        },
    },
    {
        field: "active",
        headerName: "State",
        renderCell: function render({row}) {
            return <>{(row.active) ? "active" : "inactive"}</>
        }
    },
    {
        field: "id",
        headerName: "",
        minWidth: 10,
        renderCell: function render({row}) {
            return (
                <>
                    <EditButton recordItemId={row.id} hideText/><ShowButton recordItemId={row.id} hideText/>
                </>
            );
        },
    },
];

export const ProjectList = () => {
    const { dataGridProps } = useDataGrid<ProjectDTO>();

    return (
        <List>
            <DataGrid {...dataGridProps} columns={columns} autoHeight />
        </List>
    );
};