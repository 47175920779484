import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {CircularProgress, Typography} from "@pankod/refine-mui";

const AlertDialog: React.FC<{
    title: string,
    description: string,
    agreeCB: () => void,
    cancelCB: () => void,
    display: boolean,
    wait?: boolean
}> = ({title, description, display, agreeCB, cancelCB, wait}) => {

    const handleAgree = () => {
        agreeCB();
    }

    const close = () => {
        cancelCB();
    }

    return (
        <div>
            <Dialog
                open={display}
                onClose={close}
                disableEscapeKeyDown={!wait}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {description}
                    </DialogContentText>
                </DialogContent>
                {(wait && wait) && (
                    <DialogContent>
                        <Typography align={"center"}>
                            <CircularProgress color={"secondary"}/>
                        </Typography>
                    </DialogContent>
                )}
                <DialogActions>
                    <Button onClick={close} disabled={(wait && wait)}>Cancel</Button>
                    <Button onClick={handleAgree} autoFocus disabled={(wait && wait)}>Confirm</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default AlertDialog;