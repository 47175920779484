import React, {ChangeEvent, SyntheticEvent, useEffect, useState} from "react";
import {HttpError, useNotification} from "@pankod/refine-core";
import {
    Box,
    TextField,
    Create,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel,
    Checkbox, Alert,
} from "@pankod/refine-mui";
import {useForm, Controller, FieldError} from "@pankod/refine-react-hook-form";
import {ProjectDTO, ProjectPermission} from "../../generated/UsherTypes";

export const ProjectCreate: React.FC = () => {
    const {
        refineCore: { formLoading },
        saveButtonProps,
        register,
        control,
        formState: { errors },
    } = useForm<ProjectDTO, HttpError>();

    const { open } = useNotification();

    const [generateKeypair, setGenerateKeypair] = useState(true);

    const [permissions, setPermissions] = useState([] as string[]);
    const [enabledPermissions, setEnabledPermissions] = useState([] as string[]);

    useEffect(() => {
        setPermissions(Object.entries(ProjectPermission).map((p) => {return p[0]}));
    }, []);

    const handleKeypairState = (e: ChangeEvent<HTMLInputElement>) => {
        setGenerateKeypair((e.target.value === "create"));
    }

    const addPermission = (n: string) => {
        if (n === 'ACCELEROMETER' && (!enabledPermissions.includes('GPS') && !enabledPermissions.includes('RELATIVE_GPS'))) {
            open?.({
                type: "error",
                message: "You must select 'GPS' or 'RELATIVE GPS' permission to use accelerometer",
                description: "GPS required",
            });
            return;
        }
        // GPS and RELATIVE_GPS are mutually exclusive
        if ((n === 'GPS' || n === 'RELATIVE_GPS')
            && (enabledPermissions.includes('GPS') || enabledPermissions.includes('RELATIVE_GPS'))) {
            const newPermissions = enabledPermissions.filter((p) => (p !== 'GPS' && p !== 'RELATIVE_GPS'));
            setEnabledPermissions([...newPermissions, ...[n]]);
        } else {
            setEnabledPermissions([...enabledPermissions, ...[n]]);
        }
    }

    const handlePermission = (e: SyntheticEvent<Element, Event>, n: string, checked: boolean) => {
        if (checked) {
            addPermission(n);
        }
        else {
            setEnabledPermissions([...enabledPermissions.filter((p) => p !== n)])
        }
    }

    return (
        <Create isLoading={formLoading} saveButtonProps={saveButtonProps}>
            <Box
                component="form"
                sx={{ display: "flex", flexDirection: "column" }}
                autoComplete="off">
                <TextField
                    {...register("name", { required: "Name is required" })}
                    error={!!errors?.name}
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label="Project Name"
                    name="name"
                    autoFocus
                />
                <TextField
                    {...register("description", { required: "Project description is required", maxLength: 3000 })}
                    error={!!errors?.description}
                    helperText={errors.description && `${errors.description.message}`}
                    margin="normal"
                    multiline rows={3}
                    fullWidth
                    id="description"
                    label="Project description"
                    name="description"
                />
                <Controller control={control} name={"keypair"} render={({field}) => (
                    <FormControl>
                        <FormLabel component="legend" id={"keypair-select-label"}>Project keypair</FormLabel>
                        <RadioGroup
                            aria-labelledby={"keypair-select-label"} name={"keypair-select"}
                            onChange={handleKeypairState} value={(generateKeypair) ? "create" : "upload"}>
                            <FormControlLabel {...register("keypair_select")} value="create"
                                              control={<Radio name={"keypair-select"} />} label="Create keypair"/>
                            <FormControlLabel {...register("keypair_select")} value="upload"
                                              control={<Radio name={"keypair-select"} />} label="Upload public key" />
                        </RadioGroup>
                    </FormControl>
                )}/>
                {!generateKeypair && (
                    <>
                        <TextField
                            {...register("public_key", { required: "Public key is required" })}
                            error={!!errors?.public_key}
                            margin="normal"
                            multiline rows={5}
                            required
                            fullWidth
                            id="public_key"
                            label="Public Key"
                            name="public_key"
                            placeholder={"-----BEGIN RSA PUBLIC KEY-----\n[key contents]\n-----END RSA PUBLIC KEY-----"}
                        />
                    </>
                )}
                <Controller control={control} name={"permissions"} render={({field}) => (
                    <FormControl>
                        <FormLabel component={"legend"}>Project Permissions</FormLabel>
                        <Alert severity={"warning"}>
                            Permissions cannot be edited once the project has been created
                        </Alert>
                        {Object.entries(ProjectPermission).sort().map((p, k) => (
                            <>
                                <FormControlLabel
                                    key={`permission_key_${k}`}
                                    {...register("permissions")}
                                    value={p[0]}
                                    checked={enabledPermissions.includes(p[0])}
                                    // disable field if it's a GPS field and the accelerometer has been selected
                                    disabled={(p[0] === 'GPS' && enabledPermissions.includes('ACCELEROMETER'))
                                      || (p[0] === 'RELATIVE_GPS' && enabledPermissions.includes('ACCELEROMETER'))}
                                    onChange={(e, b) => handlePermission(e, p[0], b)}
                                    control={<Checkbox/>} id={`permission_${p[0]}`} label={p[0]}/>
                                {(errors[p[0]]) && (
                                    <>
                                        <Alert>Error</Alert>
                                    </>
                                )}
                            </>
                        ))}
                    </FormControl>
                )}/>
            </Box>
        </Create>
    );
};
