import React from "react";
import { useRouterContext, TitleProps } from "@pankod/refine-core";
import { Button } from "@mui/material";
import full from '../img/full-logo-60.svg';
import narrow from '../img/narrow-logo.svg';

export const CustomTitle: React.FC<TitleProps> = ({ collapsed }) => {

    // borrowed from https://github.com/refinedev/refine/blob/master/packages/mui/src/components/layout/title/index.tsx

    const { Link } = useRouterContext();

    return (
        <Button fullWidth variant="text" disableRipple>
            <Link to="/">
                {collapsed ? (
                    <img
                        src={narrow}
                        alt="Usher Admin"
                        width="20px"
                        style={{ maxHeight: "40px" }}
                    />
                ) : (
                    <img
                        src={full}
                        alt="Usher Admin"
                        width="140px"
                    />
                )}
            </Link>
        </Button>
    );
};