import {HttpError} from "@pankod/refine-core";
import {
    Edit,
    Box,
    TextField,
    Button, Alert, FormControlLabel, FormGroup, Switch,
} from "@pankod/refine-mui";
import {useForm} from "@pankod/refine-react-hook-form";
import React, {useState} from "react";
import {KeyDTO, ProjectDTO} from "../../generated/UsherTypes";
import AlertDialog from "../../components/AlertDialog";
import {Messages} from "../../components/Messages";
import axios, {AxiosResponse} from "axios";
import {axiosInstance} from "@pankod/refine-simple-rest";

export const ProjectEdit: React.FC = () => {
    const {
        refineCore: {formLoading, queryResult, id},
        saveButtonProps,
        register,
        control,
        setValue,
        resetField,
        formState: {errors},
    } = useForm<ProjectDTO, HttpError>();

    // key editing
    const [showConfirmEdit, setShowConfirmEdit] = useState(false);
    const [permitKeyEdit, setPermitKeyEdit] = useState(false);
    const toggleEdit = () => {
        if (permitKeyEdit) {
            setPermitKeyEdit(false);
            return;
        }
        else {
            setShowConfirmEdit(true);
        }
    }

    // key regeneration
    const [projectId, setProjectId] = useState("");
    const [showRegenerate, setShowRegenerate] = useState(false);
    const [hasGeneratedKey, setHasGeneratedKey] = useState(false);
    const [regenerating, setRegenerating] = useState(false);
    const [regenerationDescription, setRegenDescription] = useState(Messages.regeneration.default);
    const toggleRegenerate = () => {
        if (showRegenerate) {
            setRegenerating(false);
            setShowRegenerate(false);
            setRegenDescription(Messages.regeneration.default);
        }
        else {
            setShowRegenerate(true);
        }
    }

    // project enabled label
    const [projectStatusLabel, setProjectStatusLabel] = useState("");
    const [projectStatus, setProjectStatus] = useState(false);

    function downloadPrivateKey(privateKey: string) {
        const dl = document.createElement('a');
        dl.setAttribute('href', `data:text/plain;charset=utf-8,` +
            '-----BEGIN RSA PRIVATE KEY-----\n' +
            encodeURIComponent(privateKey) + '\n' +
            '-----END RSA PRIVATE KEY-----');
        dl.setAttribute('download', `project_${id}.pem`);

        dl.style.display = 'none';
        document.body.appendChild(dl);
        dl.click();

        document.body.removeChild(dl);
    }

    const regenerateKey = () => {
        setRegenerating(true);
        setTimeout(() => {
            setRegenDescription(Messages.regeneration.in_progress);
            console.debug("Regenerating key for project", projectId);
            axiosInstance.post<string, AxiosResponse<KeyDTO>>(`/api/admin/projects/${id}/key?regenerate`, {}, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
                .then((res) => {
                    console.log("got response", res);
                    if (res.status === 200) {
                        return res.data;
                    }
                    throw new Error("Unable to get keypair");
                })
                .then((keypair) => {
                    setRegenerating(false);
                    setRegenDescription(Messages.regeneration.default);
                    setHasGeneratedKey(true);
                    setShowRegenerate(false);
                    setValue('publicKey', keypair.publicKey || '');
                    if (keypair.privateKey) {
                        downloadPrivateKey(keypair.privateKey);
                    }
                })
                .catch(() => {
                    setRegenerating(false);
                    setRegenDescription(Messages.regeneration.error);
                })
        }, 1000);
    }

    return (
        <Edit isLoading={formLoading} saveButtonProps={saveButtonProps}>
            <Box
                component="form"
                sx={{display: "flex", flexDirection: "column"}}
                autoComplete="off">
                <TextField
                    {...register("created")}
                    disabled
                    margin="normal"
                    fullWidth
                    id="created"
                    label="Created"
                    name="created"
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    {...register("name", {required: "Project name is required"})}
                    error={!!errors?.name}
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label="Name"
                    name="name"
                    defaultValue={""}
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    {...register("pin", {disabled: true})}
                    margin="normal"
                    fullWidth
                    id="pin"
                    label="Enrolment PIN"
                    name="pin"
                    defaultValue={""}
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    {...register("description")}
                    error={!!errors?.description}
                    margin="normal"
                    required
                    fullWidth
                    id="description"
                    label="Description"
                    name="description"
                    InputLabelProps={{ shrink: true }}
                />
                <FormGroup>
                    <FormControlLabel
                        {...register("active", {
                            setValueAs: (e) => {
                                setProjectStatus(e);
                            },
                            onChange: (event) => {
                                setProjectStatus(event.target.checked);
                            }
                        })}
                        control={<Switch checked={projectStatus} />} label={(projectStatus) ? "active" : "inactive"} />
                </FormGroup>
                <TextField
                    {...register("publicKey")}
                    error={!!errors?.publicKey}
                    margin="normal"
                    required
                    fullWidth
                    disabled={!permitKeyEdit}
                    multiline rows={10}
                    id="publicKey"
                    label="Public Key"
                    name="publicKey"
                    InputLabelProps={{ shrink: true }}
                />
                {hasGeneratedKey && (
                    <Alert severity={"warning"} style={{marginBottom: 5}}>
                        A new keypair has been generated and the private key has been downloaded to your browser.
                        Note this key cannot be retrieved later: it is not stored on the server. <strong>If you lose access
                        to the private key you will not be able to decrypt any project data.</strong>
                    </Alert>
                )}
                <div>
                    <Button value={"edit_key"} type={"button"} variant={"outlined"} style={{marginRight: 5}} onClick={() => toggleEdit()}>
                        {permitKeyEdit && (
                            <>Cancel Edit</>
                        )}
                        {!permitKeyEdit && (
                            <>Edit Key</>
                        )}
                    </Button>
                    <Button value={"regenerate_key"} type={"button"} variant={"outlined"} onClick={() => {
                        setProjectId((id) ? id.toString() : '');
                        toggleRegenerate();
                    }}>Regenerate Key</Button>
                </div>
            </Box>
            <AlertDialog display={showConfirmEdit} title={"Confirm edit"}
                         description={"Editing the key will stop clients from sending data until they have retrieved the new key"}
                         agreeCB={() => {
                             setPermitKeyEdit(true);
                             setShowConfirmEdit(false);
                         }}
                         cancelCB={() => {
                             setShowConfirmEdit(false);
                         }}
            />
            <AlertDialog title={"Regenerate key"}
                         description={regenerationDescription}
                         wait={regenerating}
                         agreeCB={() => {regenerateKey()}}
                         cancelCB={() => {toggleRegenerate()}}
                         display={showRegenerate}/>
        </Edit>
    );
};

